import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css"
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ClientList from './ClientList';
import ClientEdit from "./ClientEdit";
import AuthService from "./services/auth.service";
import Login from "./components/login";
import Register from "./components/register";
import Profile from "./components/profile";
import Import from "./components/import";
import ShippingList from "./ShippingList";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();
    if (user) {
      this.setState({
        currentUser: user,
      });
    }
  }

  logOut() {
    AuthService.logout();
    this.setState({
      currentUser: undefined,
    });
  }
  render() {
    return (
        <Router>
            <Route path='/' exact={true} component={Login}/>
            <Route path='/login' component={Login}/>
            <Route path='/register' component={Register}/>
            <Route path='/profile' component={Profile}/>
            <Route path='/clients' exact={true} component={ClientList}/>
            <Route path='/clients/:id' component={ClientEdit}/>
            <Route path='/min-side' component={ClientEdit}/>
            <Route path='/register-customer' component={ClientEdit}/>
            <Route path='/import' component={Import}/>
            <Route path='/shippingList' exact={true} component={ShippingList}/>
        </Router>
    )
  }
}

export default App;