import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";
import { FaUserAlt } from 'react-icons/fa';

import { withRouter, Link } from 'react-router-dom';
import AppNavbar from "../AppNavbar";

const required = value => {
  if (!value) {
    return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
    );
  }
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }


  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();



    if (this.checkBtn.context._errors.length === 0) {
      console.log(this.state);
      AuthService.login(this.state.username, this.state.password).then(
          () =>
              this.props.history.push("/min-side")
          ,
          error => {
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            this.setState({
              loading: false,
              message: resMessage
            });
          }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    return (
        <div className="col-md-12">
          <AppNavbar/>
          <div className="card card-container">
            <h1 style={{ fontSize: 70 }}><center><FaUserAlt /></center> </h1>

            <Form
                onSubmit={this.handleLogin}
                ref={c => {
                  this.form = c;
                }}
            >
              <div className="form-group">
                <label htmlFor="username">Brukernavn</label>
                <Input
                    type="text"
                    className="form-control"
                    name="username"
                    value={this.state.username}
                    onChange={this.onChangeUsername}
                    validations={[required]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Passord</label>
                <Input
                    type="password"
                    className="form-control"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                    validations={[required]}
                />
              </div>

              <div className="form-group">
                <br />
                <button
                    className="btn btn-primary btn-block"
                    disabled={this.state.loading}
                >
                  {this.state.loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Logg inn</span>
                </button>
              </div>

              <div className="form-group">
                <br />
                Har du ikke en konto?
                <Link to={"/register"}> Register</Link>
              </div>
              {this.state.message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {this.state.message}
                    </div>
                  </div>
              )}
              <CheckButton
                  style={{ display: "none" }}
                  ref={c => {
                    this.checkBtn = c;
                  }}
              />
            </Form>
          </div>
        </div>
    );
  }
}

export default withRouter(Login);