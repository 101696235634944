import React, {Component} from 'react';
import {
  Button,
  ButtonGroup,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import AppNavbar from './AppNavbar';
import {Link} from 'react-router-dom';
import AuthService from "./services/auth.service";

class ClientList extends Component {

  constructor(props) {
    super(props);
    this.state = {clients: [], modal: false};
    this.remove = this.remove.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) this.setState({ redirect: "/login" });
    this.setState({ currentUser: currentUser, userReady: true })

    fetch('/clients/user/'+currentUser.id)
    .then(response => response.json())
    .then(data => this.setState({clients: data, modal: false}));
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  async remove(id) {
    await fetch(`/clients/${id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(() => {
      let updatedClients = [...this.state.clients].filter(i => i.id !== id);
      this.setState({clients: updatedClients});
    });
  }

  render() {
    const {clients} = this.state;
    const clientList = clients.map(client => {
      return <tr key={client.id}>
        <td style={{whiteSpace: 'nowrap'}}>{client.firmaNavn}</td>
        <td>{client.epost}</td>
        <td>
          <ButtonGroup>
            <Button size="sm" color="success" tag={Link}
                    to={"/clients/" + client.id}>Endre </Button> {'\u00A0\u00A0'}
          </ButtonGroup>
          <ButtonGroup>
            <Button size="sm" color="danger"
                    onClick={() => this.toggle}> Slett</Button>
          </ButtonGroup>
        </td>
      </tr>
    });

    return (
        <div>
          <AppNavbar/>
          <Container fluid>
            <div className="float-right">
              <Button color="success" tag={Link} to="/clients/new">Oprett
                kunde</Button>
            </div>
            <Table className="mt-4">
              <thead>
              <tr>
                <th width="30%">Firmanavn</th>
                <th width="30%">Epost</th>
                <th width="40%"></th>
              </tr>
              </thead>
              <tbody>
              {clientList}
              </tbody>
            </Table>
          </Container>
          <Modal isOpen={this.state.modal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>Modal title</ModalHeader>
            <ModalBody>
              Er du sikker på at du vil slette denne kunden?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.toggle}>
                Ja
              </Button>{' '}
              <Button color="secondary" onClick={this.toggle}>
                Nei
              </Button>
            </ModalFooter>
          </Modal>
        </div>
    );
  }
}

export default ClientList;