import React, {Component} from 'react';
import {Navbar, NavbarBrand, Container} from 'reactstrap';
import {Link} from 'react-router-dom';
import AuthService from "./services/auth.service";
import logo from "./common/logo.png";
import {
  FaUserAlt,
  FaSignOutAlt,
  FaSignInAlt,
  FaUpload,
  FaShippingFast
} from 'react-icons/fa';

export default class AppNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {isOpen: false};
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  logOut() {
    AuthService.logout();
    window.location.reload();
    this.setState({
      currentUser: undefined,
      redirect: "/",
    });
  }

  render() {
    if (AuthService.getCurrentUser()) {
      return <div>
        <Navbar className="navbar-custom" dark>
          <Container>
            <NavbarBrand tag={Link} to="/min-side"><img className={".logo-pp"}
                                                src={logo} alt={"min-side"}/></NavbarBrand>
            <div>
              <NavbarBrand tag={Link} to="/min-side"><FaUserAlt /> PROFIL </NavbarBrand>
              <NavbarBrand tag={Link} to="/import"><FaUpload /> IMPORT </NavbarBrand>
              <NavbarBrand tag={Link} to="/shippingList"><FaShippingFast /> SENDINGER </NavbarBrand>
              <NavbarBrand tag={Link} to="" onClick={this.logOut}> <FaSignOutAlt /> LOGG UT</NavbarBrand>
            </div>
          </Container>

        </Navbar>
      </div>;
    } else {
      return <div>
        <Navbar className="navbar-custom" dark>
          <Container>
            <NavbarBrand tag={Link} to="/login"><img className={".logo-pp"}
                                                src={logo} alt={"logo"}/></NavbarBrand>
            <NavbarBrand tag={Link} to="/login"><FaSignInAlt /> LOGG INN</NavbarBrand>
          </Container>
        </Navbar>
      </div>;

    }

  }
}