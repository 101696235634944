import React, { useState, useEffect } from 'react';
import {
  Container, Navbar, NavbarBrand,
  Table,
} from 'reactstrap';
import AppNavbar from './AppNavbar';
import AuthService from "./services/auth.service";
import {FaShippingFast, FaRedo, FaArrowAltCircleRight, FaFileExport, FaInfoCircle} from "react-icons/fa";
import axios from "axios";
import {Link} from "react-router-dom";
import { unparse } from 'papaparse';
import Moment from 'react-moment';
import { FiCommand } from "react-icons/fi";


function ShippingList(props) {
  const [shippings, setShippings] = useState([]);
  const [objectList, setObjectList] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const currentMonth = new Date().getMonth() + 1;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      props.history.push("/login");
    }
    setCurrentUser(currentUser);

    fetch('/clients/client/id/' + currentUser.id, {mode: 'cors'})
    .then(response => response.json())
    .then(data => setObjectList([data]));

    fetch('/shipping/list/' + currentUser.companyId + '/' + currentMonth,
        {mode: 'cors'})
    .then(response => response.json())
    .then(data => setShippings(data));
  }, []);

  const handleChange = (id, event) => {
    const json = {
      "id": id,
      "productId": event.target.value
    }

    axios.post("/shipping/product", json)
    .then(response => {
      if (response.data) {
        window.location.reload();
      }
      return response.data;
    });
  };
  const monthChoose = (event) => {
    fetch('/shipping/list/' + currentUser.companyId + '/' + event.target.value,
        {mode: 'cors'})
    .then(response => response.json())
    .then(data => setShippings(data));
  };

  const update = (value) => {
    let selectedShippings = [...objectList, value];
    if (objectList.includes(value)) {
      selectedShippings = selectedShippings.filter(
          shipping => shipping !== value);
    }
    setObjectList(selectedShippings);
  }

  const updatePayment = (url) => {
    axios.interceptors.request.use(request => {
      return request
    })

    axios.interceptors.response.use(response => {
      return response
    })

    window.open(url, "s",
        "width= 640, height= 480, left=0, top=0, resizable=yes, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=no, copyhistory=no");
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (objectList.length === 1) {
      alert("Du må velge minst en sending");
    } else {
      setLoading(true);
      axios.post("/shipping/book", objectList)
      .then(response => {
        if (response.data) {
          updatePayment(response.data);
          setTimeout(() => {
            handleUpdateShipping();
          }, 3000);
        }
        setLoading(false);
        return response.data;
      });
    }
  }

  const handleReturnSubmit = (e) => {

    e.preventDefault();

    if (objectList.length === 1) {
      alert("Du må velge minst en sending");
    } else {
      const confirmBox = window.confirm(
          "Er du sikker på at du vil booke retur for denne sendingen?"
      )
      if (confirmBox === true) {
        setLoading(true);
        axios.post("/shipping/return", parseInt(objectList[1]))
        .then(response => {
          if (response.data) {
            updatePayment(response.data);
            setTimeout(() => {
              handleUpdateReturnShipping(objectList[1]);
            }, 3000);
          }
          setLoading(false);
          return response.data;
        });
      }
    }
  }

  const handleUpdateShipping = () => {
    return axios
    .post("/shipping/update", objectList)
    .then(response => {
      if (response.data) {
        updatePayment(response.data);
      }
      window.location.reload();

      return response.data;
    });
  }
  const handleUpdateReturnShipping = () => {
    return axios
    .post("/shipping/update-return", objectList)
    .then(response => {
      if (response.data) {
        updatePayment(response.data);
      }
      window.location.reload();
      return response.data;
    });
  }

  const handleExport = () => {

    function formatDate(dato) {
      let date = new Date(dato);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate() + 1;
      if (day < 10) {
        day = '0' + day;
      }
      if (month < 10) {
        month = '0' + month;
      }
      date = day + '.' + month + '.' + year
      return date;
    }

    const parsedBody = shippings.map(res => ({
      Sendingstype: "PostNord Logistics",
      Sendingsnummer: res.sporingsNummer,
      Status: "EDI sendt",
      Date: formatDate(res.dato),
      "Pakke/Transp.type": res.productId,
      Mottaker: res.mottakerNavn,
      PostAdr1: res.mottakerAdresse,
      Postnr: res.postNummer,
      Poststed: res.postSted,
      Kontaktperson: res.mottakerNavn,
      Telefon: res.telefon,
      Ordrenummer: res.ordreNummer
    }))

    const csvShippings = unparse(parsedBody, {
      quotes: true,
    });
    const blob = new Blob([csvShippings]);
    const a = document.createElement('a');

      a.href = URL.createObjectURL(blob, {type: 'text/plain'});
      a.download = 'CSV Export File';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

}
    const shippingList = shippings.map(shipping => {
      return <tr key={shipping.id}>
        <td><input
            type="checkbox"
            className="form-check-input"
            onChange={() => update(shipping.id)}
        /></td>
        <td>{shipping.ordreNummer}</td>
        <td style={{whiteSpace: 'nowrap'}}>{shipping.mottakerNavn}</td>
        <td>{shipping.gateNavn}</td>
        <td>{shipping.postNummer}</td>
        <td>{shipping.postSted}</td>
        <td>{shipping.telefon}</td>
        <td>
          <select value={shipping.productId} onChange={(e) =>
              handleChange(shipping.id, e)}>
            <option value="2027">PostNord M</option>
            <option value="2026">PostNord L</option>
            <option value="2028">PostNord XL</option>
            <option value="2029">PostNord XXL</option>
            <option value="2034">PostNord Bedrift S</option>
            <option value="2036">PostNord Bedrift M</option>
            <option value="2037">PostNord Bedrift L</option>
            <option value="2038">PostNord Pakkeautomat</option>
            <option value="2035">PostNord Pallefrakt</option>
          </select>
        </td>
        <td>{shipping.status === "S"?  <div className="tooltipd"><FaInfoCircle />
          <span className="tooltiptextd">{shipping.sporingsNummer}</span>
        </div>
             : "" }</td>
        <td>{<Moment format="DD-MM-YYYY HH:mm">{shipping.dato}</Moment>}</td>
      </tr>
    });

    return (
        <div>
          <AppNavbar/>
          <Container>
            <div style={{
              textAlign: "center"
            }}>
              <p><br /></p>
              <h1> <FaShippingFast /> Sendinger</h1>
              <p><br /></p>
            </div>
            <div style={{
              textAlign: "right",
              marginRight: "20px"
            }}>
                <select defaultValue={currentMonth} onChange={(e) =>
                    monthChoose(e)}>
                  <option value="1">Januar 2024</option>
                  <option value="2">Februar 2024</option>
                  <option value="3">Mars 2024</option>
                  <option value="4">April 2024</option>
                  <option value="5">Mai 2024</option>
                  <option value="6">Juni 2024</option>
                  <option value="7">Juli 2024</option>
                  <option value="8">August 2024</option>
                  <option value="9">September 2024</option>
                  <option value="10">Oktober 2024</option>
                  <option value="11">November 2024</option>
                  <option value="12">Desember 2024</option>
                </select>
            </div>
            {!loading ? (
            <Table className="mt-4 shippingListTable">
              <thead>
              <tr>
                <th width="2%"></th>
                <th width="5%">Ordrenummer</th>
                <th width="5%">Mottaker</th>
                <th width="10%">Gatenavn</th>
                <th width="5%">Postnr</th>
                <th width="5%">Poststed</th>
                <th width="5%">Telefon</th>
                <th width="5%">Product</th>
                <th width="5%">Sporing</th>
                <th width="10%">Dato</th>
              </tr>
              </thead>
              <tbody>

              {shippingList}

              </tbody>
            </Table>
            ) : (<center><FiCommand className="loading-icon" /> Loading..</center>)}
            <div style={{
              textAlign: "right",
            }}>
              <Navbar className="navbar-inside-right">
                <Container>
                  <NavbarBrand tag={Link} onClick={handleSubmit}><FaArrowAltCircleRight /> Book sendingen</NavbarBrand>
                  <NavbarBrand tag={Link} onClick={handleReturnSubmit}><FaRedo /> Book retur</NavbarBrand>
                  <NavbarBrand tag={Link} to="/shippingList"  onClick={handleExport}><FaFileExport /> Eksport</NavbarBrand>
                </Container>
              </Navbar>
            <br/>
            <br/>
            <br/>
            <br/>
            </div>
          </Container>

        </div>
    );

}

export default(ShippingList);