import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {
  Button,
  Container,
  Form,
  FormGroup,
  FloatingLabel,
  Row,
  Col
} from 'react-bootstrap';
import AppNavbar from './AppNavbar';
import AuthService from "./services/auth.service";

class ClientEdit extends Component {

  emptyItem = {
    epost: '',
    orgNummer: '',
    firmaNavn: '',
    telefon: '',
    kontoNummer: '',
    adresse: '',
    postNummer: '',
    postSted: '',
    postLand: '',
    fakturaAdresse: '',
    fakturaPostNummer: '',
    fakturaSted: '',
    fakturaLand: '',
    kommentar: '',
    pakkepostId: '',
    postnordId: '',
    defaultLeverandoer: '',
    userId: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      item: this.emptyItem
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) this.props.history.push('/login');

    if (this.props.match.params.id !== 'new') {
      // Get parent client to show on min-side
      if (this.props.match.path === '/min-side') {
        try {
          const client = await (await fetch(
              `/clients/client/${AuthService.getCurrentUser().id}`)).json()
          isJsonString(client)
          this.setState({item: client});
        } catch (e) {
          console.log(e);
        }

      } else {
        // This is the client to be shown after clicking edit client on the client list
        const client = await (await fetch(
            `/clients/${this.props.match.params.id}`)).json();
        this.setState({item: client});
      }
    }
    // Else just show an empty form to add new client
    function isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    }
  }

  handleChange(event) {

    const target = event.target;
    const value = target.value;
    const name = target.name;
    let item = {...this.state.item};
    item[name] = value;
    item.userId = AuthService.getCurrentUser().id;
    if (this.props.match.path === '/min-side') {
      item.parent = 1;
    }
    this.setState({item});
  }

  async handleSubmit(event) {
    event.preventDefault();
    const {item} = this.state;
    await fetch('/clients' + (item.id ? '/' + item.id : ''), {
      method: (item.id) ? 'PUT' : 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(item),
    })
    .then(() => {
      this.setState({ type: 'success' });
      let user = localStorage.getItem('user');
      user = user ? JSON.parse(user) : {};
      user['companyId'] = item.id;
      localStorage.setItem('user', JSON.stringify(user));
    })
    .catch((error) => {
      this.setState({ type: 'error', error });
    });
    this.props.history.push('/min-side');
  }


  render() {
    const {item} = this.state;
    const title = <h2>{item.id ? 'Kundeinformasjon' : 'Kundeinformasjon'}</h2>;

    return <div>
      <AppNavbar/>
      <Container>

        <p><br /></p>
        <h1><center>{item.firmaNavn}</center></h1>
        <p><br /></p>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col>
              {title}
              <FloatingLabel
                  controlId="epost"
                  label="E-post"
                  className="mb-3"
              >
                <Form.Control type="email" name="epost"
                              value={item.epost || ''}
                              onChange={this.handleChange}
                              placeholder="name@example.com"
                />
              </FloatingLabel>
              <FloatingLabel
                  controlId="orgNummer"
                  label="Org.nummer"
                  className="mb-3"
              >
                <Form.Control type="text" name="orgNummer"
                              value={item.orgNummer || ''}
                              onChange={this.handleChange}
                              autoComplete="orgNummer"
                              placeholder="Org. Nummer"/>
              </FloatingLabel>
              <FloatingLabel
                  controlId="firmaNavn"
                  label="Firmanavn"
                  className="mb-3"
              >
                <Form.Control type="text" name="firmaNavn"
                              value={item.firmaNavn || ''}
                              onChange={this.handleChange}
                              autoComplete="firmaNavn"
                              placeholder="Firmanavn"/>
              </FloatingLabel>
              <FloatingLabel
                  controlId="telefon"
                  label="Telefon"
                  className="mb-3"
              >
                <Form.Control type="text" name="telefon"
                              value={item.telefon || ''}
                              onChange={this.handleChange}
                              autoComplete="telefon"
                              placeholder="Telefon"/>
              </FloatingLabel>
              <FloatingLabel
                  controlId="kontoNummer"
                  label="Kontonummer"
                  className="mb-3"
              >
                <Form.Control type="text" name="kontoNummer"
                              value={item.kontoNummer || ''}
                              onChange={this.handleChange}
                              autoComplete="kontoNummer"
                              placeholder="Kontonummer"/>
              </FloatingLabel>

              <h2>Postadresse</h2>
              <FloatingLabel
                  controlId="adresse"
                  label="Gatenavn"
                  className="mb-3"
              >
                <Form.Control type="text" name="adresse"
                              value={item.adresse || ''}
                              onChange={this.handleChange}
                              autoComplete="adresse"
                              placeholder="Gatenavn"/>
              </FloatingLabel>
              <FloatingLabel
                  controlId="postNummer"
                  label="Postnummer"
                  className="mb-3"
              >
                <Form.Control type="text" name="postNummer"
                              value={item.postNummer || ''}
                              onChange={this.handleChange}
                              autoComplete="postNummer"
                              placeholder="Postnummer"/>
              </FloatingLabel>
              <FloatingLabel
                  controlId="postSted"
                  label="Poststed"
                  className="mb-3"
              >
                <Form.Control type="text" name="postSted"
                              value={item.postSted || ''}
                              onChange={this.handleChange}
                              autoComplete="postSted"
                              placeholder="Poststed"/>
              </FloatingLabel>
              <FloatingLabel
                  controlId="postLand"
                  label="Land"
                  className="mb-3"
              >
                <Form.Control type="text" name="postLand"
                              value={item.postLand || ''}
                              onChange={this.handleChange} autoComplete="email"
                              placeholder="Land"/>
              </FloatingLabel>
            </Col>
            <Col>
              <h2>Fakturaadresse</h2>
              <FloatingLabel
                  controlId="fakturaAdresse"
                  label="Gatenavn"
                  className="mb-3"
              >
                <Form.Control type="text" name="fakturaAdresse"
                              value={item.fakturaAdresse || ''}
                              onChange={this.handleChange}
                              autoComplete="fakturaAdresse"
                              placeholder="Gatenavn"/>
              </FloatingLabel>
              <FloatingLabel
                  controlId="fakturaPostNummer"
                  label="Postnummer"
                  className="mb-3"
              >
                <Form.Control type="text" name="fakturaPostNummer"
                              value={item.fakturaPostNummer || ''}
                              onChange={this.handleChange}
                              autoComplete="fakturaPostNummer"
                              placeholder="Postnummer"/>
              </FloatingLabel>
              <FloatingLabel
                  controlId="fakturaSted"
                  label="Sted"
                  className="mb-3"
              >
                <Form.Control type="text" name="fakturaSted"
                              value={item.fakturaSted || ''}
                              onChange={this.handleChange}
                              autoComplete="fakturaSted"
                              placeholder="Poststed"/>
              </FloatingLabel>

              <FloatingLabel
                  controlId="fakturaLand"
                  label="Land"
                  className="mb-3"
              >
                <Form.Control type="text" name="fakturaLand"
                              value={item.fakturaLand || ''}
                              onChange={this.handleChange}
                              autoComplete="fakturaLand"
                              placeholder="Land"/>
              </FloatingLabel>
              <p><br /><br /></p>
                <Form.Label for="fakturaAdresse"><h2>Transportør</h2></Form.Label>

              <Form.Control as="select" name="defaultLeverandoer"
                            aria-label="Default transportør"
                            value={item.defaultLeverandoer || ''}
                            onChange={this.handleChange}
              >
                <option value="ingen">-- Velg default transportør --</option>
                <option value="pakkepost">Pakkepost</option>
                <option value="postnord">PostNord</option>
              </Form.Control>
              <br />

              <FloatingLabel
                  controlId="pakkepostId"
                  label="Pakkepost ID"
                  className="mb-3"
              >
                <Form.Control type="text" name="pakkepostId"
                              value={item.pakkepostId || ''}
                              onChange={this.handleChange}
                              autoComplete="pakkepostId"
                              placeholder="Pakkepost ID"/>
              </FloatingLabel>
              <FloatingLabel
                  controlId="postnordId"
                  label="Postnord ID"
                  className="mb-3"
              >
                <Form.Control type="text" name="transportId"
                              value={item.postnordId || ''}
                              onChange={this.handleChange}
                              autoComplete="postnordId"
                              placeholder="Postnord ID"/>
              </FloatingLabel>

              <p></p>
              <p><br /></p>
              <FormGroup >
                <Link to="/"><Button variant="secondary" style={{float: "right"}}>Cancel</Button></Link>
                <Button variant="primary" type="submit" style={{float: "right",marginRight:"10px"}} >Save</Button>
              </FormGroup>
            </Col>
          </Row>

        </Form>
      </Container>
    </div>
  }
}

export default withRouter(ClientEdit);