import React, {Component} from "react";
import { parse  } from 'papaparse';
import AuthService from "../services/auth.service";
import AppNavbar from "../AppNavbar";
import {
  Button,
  Container
} from 'react-bootstrap';
import {FaUpload} from "react-icons/fa";

export default class Import extends Component {

  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      userReady: false,
      allowedExtensions: ["csv"],
      data: "",
      parsedData: "",
      error: null,
      file: null,
      currentUser: { username: "" }
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) this.setState({ redirect: "/login" });
    this.setState({ currentUser: currentUser, userReady: true })
  }

  // This function will be called when
  // the file input changes
  handleFileChange(e) {

    this.setState({error: ""})

    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
      const fileExtension = inputFile?.type.split("/")[1];
      if (!this.state.allowedExtensions.includes(fileExtension)) {
        this.setState({error: "Please input a csv file"})
        return;
      }

      this.setState({file: inputFile})
    }
  };

  handleParse() {

    // If user clicks the parse button without
    // a file we show a error
    if (!this.state.file) return this.setState({error: "Enter a valid file"});

    // Initialize a reader which allows user
    // to read any file or blob.
    const reader = new FileReader();

    // Event listener on reader when the file
    // loads, we parse it and set the data.
    reader.onload = async ({ target }) => {
      const csv = parse(target.result, config);
      const parsedData = csv?.data;
      this.setState({data: parsedData, parsedData: parsedData})

      const parsedBody = parsedData.map(res => ({...res, companyId:this.state.currentUser.companyId }))

      await fetch('/shipping/create', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(parsedBody),
      }).then(() => {
        this.setState({type: 'success'});
        this.props.history.push('/shippingList')
      })
      .catch((error) => {
        this.setState({type: 'error', error});
      });
      console.log("BODY" + JSON.stringify(parsedBody));

    };
    const config = {
      delimiter: ",",
      header: true,
      dynamicTyping: false,
      skipEmptyLines: true,
    };
    reader.readAsText(this.state.file);

  };

render() {
  return (
      <div>
        <AppNavbar/>
        <Container>
        <div style={{
          textAlign: "center"
        }}>
          <p><br /></p>
          <h1> <FaUpload /> Import CSV File</h1>
          <p><br /><br /></p>
          <input
              onChange={this.handleFileChange.bind(this)}
              id="csvInput"
              name="file"
              type="File"
          />
          <div style={{
            textAlign: "center"
          }}>
            <p><br /><br /></p>
            <Button variant="primary" onClick={this.handleParse.bind(this)}>Send</Button>
          </div>
        </div>
          </Container>
      </div>
  )
}
}